import React, { useState } from "react";
import "../W3.css";

import BackImges from "../Components/backImges";
import About from "../Components/About";
import Adressifrm from "../Components/Adressifrm";
import Login from "../Components/Login";
import TrackDetails from "../Components/TrackDetails";

const Home = () => {
  const [Show, SetShow] = useState(false);
  const [ShowTracks, SetShowTracks] = useState(false);

  const LoginData = () => {
    SetShow(true);
  };

  const TracksData = () => {
    SetShowTracks(true);
  };

  const ContactClick = () => {
    const ele = document.getElementById("contact");
    ele.scrollIntoView();
  };

  const AboutClick = () => {
    const ele = document.getElementById("About");
    ele.scrollIntoView();
  };

  return (
    <div className="w3-sand">
      {/* <!-- Navbar (sit on top) --> */}
      <div className="w3-top">
        <div className="w3-bar">
          <button onClick={AboutClick} className="w3-bar-item w3-button">
            <b> HOME</b>{" "}
          </button>
          <button
            onClick={AboutClick}
            className="w3-bar-item w3-button w3-hide-small"
          >
            <b> ABOUT</b>{" "}
          </button>
          <button
            onClick={ContactClick}
            className="w3-bar-item w3-button w3-hide-small"
          >
            <b>CONTACT</b>
          </button>
          <button onClick={TracksData} className="w3-bar-item w3-button">
            <b>TRACK</b>
          </button>
          <button onClick={LoginData} className="w3-bar-item w3-button">
            <b>LOGIN</b>{" "}
          </button>
        </div>
        {/* <!-- Navbar on small screens --> */}
        <div className="w3-bar-block w3-white w3-hide w3-hide-large w3-hide-medium">
          <button onClick={AboutClick} className="w3-bar-item w3-button">
            <b>ABOUT</b>
          </button>
          <button onClick={ContactClick} className="w3-bar-item w3-button">
            <b>CONTACT</b>
          </button>
        </div>
      </div>
      {/* <!-- First Parallax Image with Logo Text --> */}
      <BackImges />
      {/* <!-- Container (About Section) --> */}
      <div id="About"></div>
      <About />
      <div className="w3-content w3-container w3-padding-64" id="contact">
        <h3 className="w3-center">WHERE I WORK</h3>
        <p className="w3-center">
          <em>I'd love your feedback!</em>
        </p>

        <div id="contact" className="w3-row w3-padding-32 w3-section">
          <Adressifrm />
          <div className="w3-col m7 w3-panel">
            <div className="w3-large w3-margin-bottom">
              <i className="w3-hover-text-black w3-xlarge w3-margin-right"></i>{" "}
              50, Janpath Rd,Ashok Nagar, Bhubaneswar,
              <br />
              <i className="w3-hover-text-black w3-xlarge w3-margin-right"></i>{" "}
              Odisha 751009 Phone: +91 9348522933
              <br />
              <i className="w3-hover-text-black w3-xlarge w3-margin-right"></i>{" "}
              Email: metrocouriercargo@rediffmail.com
              <br />
            </div>
            <p>
              Swing by for a cup of <i className="fa fa-coffee"></i>, or leave
              me a note:
            </p>
            <form action="/action_page.php" target="_blank">
              <div
                className="w3-row-padding"
                style={{ margin: "0 -16px 8px -16px" }}
              >
                <div className="w3-half">
                  <input
                    className="w3-input w3-border"
                    type="text"
                    placeholder="Name"
                    required
                    name="Name"
                  />
                </div>
                <div className="w3-half">
                  <input
                    className="w3-input w3-border"
                    type="text"
                    placeholder="Email"
                    required
                    name="Email"
                  />
                </div>
              </div>
              <input
                className="w3-input w3-border"
                type="text"
                placeholder="Message"
                required
                name="Message"
              />
              <button
                className="w3-button w3-black w3-right w3-section"
                type="submit"
              >
                <i className="fa fa-paper-plane"></i> SEND MESSAGE
              </button>
            </form>
          </div>
        </div>
      </div>
      <Login modal={{ Show, SetShow }} />
      <TrackDetails modal={{ ShowTracks, SetShowTracks }} />
    </div>
  );
};

export default Home;
