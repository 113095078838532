import React from "react";
import img2 from "../photo/imge-2.png";
import "../W3.css";

const About = () => {
  return (
    <div>
      <div className="w3-content w3-container w3-padding-64" id="about">
        <h3 className="w3-center"><b>ABOUT ME</b></h3>
        <p className="w3-sans-serif">
          Metro is a multi modal service provider and offers the most efficient
          delivery schedule through Air, Train and Surface Express.
        </p>
        <div className="w3-row">
          <div className="w3-col m6 w3-center w3-padding-large">
            <p>
              <b>
                <i className="fa fa-user w3-margin-right"></i>My Logo
              </b>
            </p>
            <br />
            <img
              src={img2}
              className="w3-round w3-image"
              alt="img2"
              width="500"
              height="333"
            />
          </div>

          {/* <!-- Hide this text on small devices --> */}
          <div className="w3-col m6 w3-padding-large w3-sans-serif">
            <p>
              Welcome to my website. Our global network relying on its overseas
              partners with whom we have built up a confidence and trust that
              has resulted fruitful tie ups. Our close collaboration and
              long-term relationship with carefully selected working partners
              enable us to guarantee our consistency in providing top services
              to our customers on a worldwide basis. Before you consider
              shipping your product to the world's ever expanding markets, it is
              important to select a logistics partner who has the experience
              required to get your product to destination in one piece, with all
              the necessary documentation and with the right destination agents
              to satisfy your client. We understand your needs and requirements
              and then provide you with a fully comprehensive package with
              respect to warehousing of your goods. We have an extensive network
              of dedicated warehousing and distribution operations along with
              the best-in-class processes and systems to ensure cost-efficient
              distribution solutions. You can avail our warehousing services
              whenever you need. You can now continue all year round production
              and store them till the arrival of demand. Our warehousing
              facility enables you to import or export without the burden of
              their security.Store away your goods safely and be tension free.
              We promise you to provide the best warehousing service.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
