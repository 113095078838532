import React from "react";
import img1 from "../photo/imge-1.jpg";
import "../W3.css";

const BackImges = () => {
  return (
    <>
      <div className="w3-display-container">
        <img
          src={img1}
          alt="img1"
          style={{
            width: "100%",
            height:"400px" ,           
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        />
        <div className="w3-display-middle" style={{ whiteSpace: "nowrap" }}>
          <span
            style={{ letterSpacing: "10px" }}
            className="w3-center w3-padding-large w3-black w3-xlarge w3-sans-serif"
          >
            METRO <span>COURIER &</span>CARGO
          </span>
        </div>
      </div>
    </>
  );
};

export default BackImges;
