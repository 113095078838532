import Db from "./Db";
import { doc, updateDoc, deleteDoc } from "firebase/firestore";

const UpdateTableRow = async (userId, Data) => {
  try {
    const DocRef = doc(Db, "ConsinData", userId);
    await updateDoc(DocRef, Data);
    return true;
  } catch (error) {
    console.log(error);
  }
};

const DeleteTableRow = async (userId) => {
  try {
    const DocRef = doc(Db, "ConsinData", userId);
    await deleteDoc(DocRef);
    return true;
  } catch (error) {
    console.log(error);
  }
};

export { UpdateTableRow, DeleteTableRow };
