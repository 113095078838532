import Db from "./Db";
import {
  doc,
  getDoc,
  getDocs,
  query,
  where,
  collection,
  orderBy,
  startAt,
  endAt,
} from "firebase/firestore";

const SelectOwnerPass = async () => {
  let Data;
  const docRef = doc(Db, "UserData", "KEw71v6eK6TJnf2DOXh8");
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    Data = docSnap.data();
  } else {
    // doc.data() will be undefined in this case
    Data = false;
  }

  return Data;
};

const CheckDbuser = async (user, pass) => {
  let Data = false;
  const q = query(
    collection(Db, "UserData"),
    where("UserName", "==", user),
    where("UserPassword", "==", pass)
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    //console.log(doc.id, " => ", doc.data()); //doc.id means auto id by firbase
    Data = doc.exists();
  });
  return Data;
};

const SelectBillOnNo = async (No) => {
  let Data = [];
  try {
    const q = query(collection(Db, "ConsinData"), where("BillNo", "==", No));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      Data.push({ index: doc.id, Doc: doc.data() });
    });
    return Data;
  } catch (error) {
    return Data;
  }
};

const SelectCheckByDt = async (No) => {
  let Data = [];
  let today = new Date(No);
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = today.getFullYear();
  try {
    const q = query(
      collection(Db, "ConsinData"),
      where("CDDay", "==", dd),
      where("CDMonth", "==", mm),
      where("CDYear", "==", yyyy)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      Data.push(doc.id);
    });
    return Data;
  } catch (error) {
    return Data;
  }
};

const SelectBillDate = async (No) => {
  let Data = [];
  let today = new Date(No);
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = today.getFullYear();
  try {
    const q = query(
      collection(Db, "ConsinData"),
      where("CDDay", "==", dd),
      where("CDMonth", "==", mm),
      where("CDYear", "==", yyyy)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      Data.push({ index: doc.id, Doc: doc.data() });
    });
    return Data;
  } catch (error) {
    return Data;
  }
};

const SelectTrackDetails = async (No) => {
  let Data = [];
  try {
    const q = query(
      collection(Db, "ConsinData"),
      where("BillNo", "==", No.usrname)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      Data.push({ Doc: doc.data() });
    });
    return Data;
  } catch (error) {
    return Data;
  }
};

const SelectFromDate = async (No) => {
  let Data = [];
  try {
    const q = query(
      collection(Db, "ConsinData"),
      orderBy("CDate"),
      startAt(No.FDate),
      endAt(No.TDate)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      Data.push({ Doc: doc.data() });
    });
    return Data;
  } catch (error) {
    return Data;
  }
};

export {
  SelectOwnerPass,
  CheckDbuser,
  SelectBillOnNo,
  SelectBillDate,
  SelectCheckByDt,
  SelectTrackDetails,
  SelectFromDate,
};
