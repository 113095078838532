import React from "react";
import "../W3.css";

const Adressifrm = () => {
  return (
    <>
      <div className="w3-col m5 w3-container">
        <div className="w3-col m4 w3-container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14971.44224556252!2d85.8395836!3d20.2646169!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5399fbe0425d3e21!2sMetro%20Courier%20%26%20Cargo!5e0!3m2!1sen!2sin!4v1607199232948!5m2!1sen!2sin"
            title="adre"
            allowFullScreen={true}
            height="300px"
            style={{ border: 0 }}
            tabIndex="0"
          />
        </div>
      </div>
    </>
  );
};

export default Adressifrm;
