import React, { useState, useEffect } from "react"; //useContext
import "../W3.css";
//import { SelectBillDate } from "../Config/Select";
import ConsignTbr from "./ConsignTbr";

const ConsignTable = (props) => {
  const [Result, SetResult] = useState(false);
  const [Edit, SetEdit] = useState("");
  const Bill = props.Gt.BillData;

  useEffect(() => {
    if (Bill) {
      let Val = Bill;
      //sort value 1 2 3 ....
      Val.sort(function (a, b) {
        return a.Doc.SL - b.Doc.SL;
      });
      SetResult(Val);
    }
  }, [Bill, SetResult]);

  useEffect(() => {
    //receive from tbr component and send to date component
    if (Edit) {
      props.Gt.SetEditOn(Edit);
      SetEdit("");
    }
    return () => SetEdit("");
  }, [Edit, props]);

  return (
    <div className="w3-container w3-round-xlarge">
      <div className="w3-responsive">
        <table className="w3-table-all w3-hoverable w3-small">
          <thead>
            <tr className="w3-pale-green">
              <th>Sl No</th>
              <th>Cons.No</th>
              <th>Lo.From</th>
              <th>Lo.To</th>
              <th>Packs.</th>
              <th>Wgth.</th>
              <th>Status</th>
              <th>Inv No</th>
              <th>Dlvry.dt</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {Result
              ? Result.map((value, index) => (
                  <ConsignTbr
                    key={value.index}
                    v={value.Doc}
                    k={value.index}
                    no={index}
                    Edit={SetEdit}
                    HomeP ={false}
                  />
                ))
              : false}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ConsignTable;
