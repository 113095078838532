import React from "react";
import "../W3.css";

const ConsignTbr = (props) => {
  let Td = props.v;
  const Pkgnm = ["Boxes", "Bages", "Packs"];
  const WgtNm = ["KG", "Ton", "Gram"];
  const Sts = ["Godown In", "Deliver In Good Condition", "In Transit"];
  const OnHandelClick = () => {
    //send data to table component
    props.Edit({ ...Td, index: props.k });
  };
  let FormPage = () => (
    <tr>
      <td>{props.no + 1}-/</td>
      <td>{Td.BillNo}</td>
      <td>{Td.LFrom}</td>
      <td>{Td.LTo}</td>
      <td>
        {Td.Packages}-{Td.PackagesNm ? Pkgnm[Td.PackagesNm] : Pkgnm[0]}
      </td>
      <td>
        {Td.Weights}-{Td.WeightsNm ? WgtNm[Td.WeightsNm] : WgtNm[0]}
      </td>
      <td>{Td.Status ? Sts[Td.Status] : Sts[0]}</td>
      <td>{Td.RefNo}</td>
      <td>{Td.DDate}</td>
      <td>
        <button onClick={OnHandelClick} className="w3-btn w3-orange">
          Edit
        </button>
      </td>
    </tr>
  );

  let HomeePage = () => (
    <tr>
      <td>{props.no + 1}-/</td>
      <td>{Td.BillNo}</td>
      <td>{Td.CDate}</td>
      <td>{Td.DDate}</td>
      <td>{Td.RefNo}</td>
      <td>{Td.LFrom}</td>
      <td>{Td.LTo}</td>
      <td>
        {Td.Packages}-{Td.PackagesNm ? Pkgnm[Td.PackagesNm] : Pkgnm[0]}
      </td>
      <td>
        {Td.Weights}-{Td.WeightsNm ? WgtNm[Td.WeightsNm] : WgtNm[0]}
      </td>
      <td>{Td.Status ? Sts[Td.Status] : Sts[0]}</td>
    </tr>
  );

  return props.HomeP ? <HomeePage /> : <FormPage />;
};

export default ConsignTbr;
