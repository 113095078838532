import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { SelectFromDate, SelectTrackDetails } from "../Config/Select";
import "../W3.css";
import Store from "./Store";

const TrackDetails = (props) => {
  const { SetTransData } = useContext(Store);
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const [inputsDate, setinputsDate] = useState({});
  const [Trackk, SetTrackOn] = useState(false);
  const [DateTrackOn, SetDateTrackOn] = useState(false);  
  const [TrackData, SetTrackData] = useState("");
  const [Comments, SetComments] = useState("");

  const Pkgnm = ["Boxes", "Bages", "Packs"];
  const WgtNm = ["Kg", "Ton", "Gram"];
  const Sts = ["Godown In", "Deliver In Good Condition", "In Transit"];

  useEffect(() => {
    if (props.modal.ShowTracks) {
      document.getElementById("id07").style.display = "block";
    }
  }, [props.modal.ShowTracks]);

  useEffect(() => {
    if (Trackk) {
      Promise.resolve(SelectTrackDetails(inputs)).then((val) => {
        if (val[0]) {
          // console.log(val[0].Doc);
          SetTrackData(val[0].Doc);
        } else {
          SetComments("No Data Found");
        }
        SetTrackOn(false);
        setInputs("");
      });
    }
    return () => SetTrackOn(false);
  }, [Trackk, inputs, SetTrackData, SetComments, setInputs, SetTrackOn]);

  useEffect(() => {
    if (DateTrackOn) {
      Promise.resolve(SelectFromDate(inputsDate)).then((val) => {
        if (val) {
          SetTransData({ Data: true, Docs: val ,Dates:inputsDate});          
          navigate("/ConsignData", { replace: false });
          //send this to ConsinData file;
        } else {
          alert("No Result Found");
        }
        SetDateTrackOn(false);
        setinputsDate("");
      });
    }
    return () => SetDateTrackOn(false);
  }, [
    DateTrackOn,
    inputsDate,
    SetComments,
    SetDateTrackOn,
    setinputsDate,
    SetTransData,
    navigate,
  ]);

  const CancelData = () => {
    document.getElementById("id07").style.display = "none";
    props.modal.SetShowTracks(false);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleDateChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setinputsDate((values) => ({ ...values, [name]: value }));
  };

  const Handeltrack = () => {
    SetTrackOn(true);
  };

  const HandelDate = () => {
    SetDateTrackOn(true);
  };

  return (
    <div className="w3-container">
      <div id="id07" className="w3-modal">
        <div
          className="w3-modal-content w3-card w3-animate-zoom w3-small"
          style={{ maxWidth: "600px" }}
        >
          <p className="w3-panel w3-center w3-padding w3-pale-green">
            <b>Consignment Track Details</b>
          </p>
          <div className="w3-container w3-sand w3-center">
            <div className="w3-section">
              <label>
                <b>Consignment No -</b>
              </label>
              <input
                className="w3-input w3-border w3-margin-bottom"
                type="text"
                placeholder="Number"
                name="usrname"
                value={inputs.usrname || ""}
                onChange={handleChange}
              />
              <input
                className="w3-input w3-border w3-margin-bottom w3-hide"
                type="text"
                placeholder="Number"
                name="usrname"
                value={inputs.usrname || ""}
                onChange={handleChange}
              />
              <button
                className="w3-button w3-block w3-green w3-section w3-padding"
                onClick={Handeltrack}
              >
                Submit
              </button>
            </div>
            <div className="w3-bar">
              <div className=" w3-bar-item">
                <label>
                  <b>From Date :-</b>
                </label>
                <input
                  type="date"
                  name="FDate"
                  value={inputsDate.FDate || ""}
                  onChange={handleDateChange}
                />
              </div>
              <div className=" w3-bar-item">
                <label>
                  <b>To Date :-</b>
                </label>
                <input
                  type="date"
                  name="TDate"
                  value={inputsDate.TDate || ""}
                  onChange={handleDateChange}
                />
              </div>
              <button
                className="w3-bar-item w3-button w3-tiny w3-green"
                onClick={HandelDate}
              >
                Go
              </button>
            </div>
          </div>

          <div className="w3-container w3-border-top w3-padding w3-light-grey w3-small">
            <p className="w3-center" style={{ display: "none" }}>
              <b>Track Details</b>
            </p>
            {TrackData ? (
              <ul className="w3-ul w3-border w3-sand w3-card w3-center">
                <li className="w3-padding-small">
                  Received Date :- {TrackData.CDate}
                </li>
                <li className="w3-padding-small">
                  Consign. Invoice No :- {TrackData.RefNo}
                </li>
                <li className="w3-padding-small">
                  Consignment No :- {TrackData.BillNo}
                </li>
                <li className="w3-padding-small">
                  Delivery Date :- {TrackData.DDate}
                </li>
                <li className="w3-padding-small">
                  Location From :- {TrackData.LFrom}
                </li>
                <li className="w3-padding-small">
                  Location To :- {TrackData.LTo}
                </li>
                <li className="w3-padding-small">
                  No Packages :- {TrackData.Packages}-
                  {TrackData.PackagesNm
                    ? Pkgnm[TrackData.PackagesNm]
                    : Pkgnm[0]}
                </li>
                <li className="w3-padding-small">
                  Weight :- {TrackData.Weights}-
                  {TrackData.WeightsNm ? WgtNm[TrackData.WeightsNm] : WgtNm[0]}
                </li>
                <li className="w3-padding-small">
                  Consignment Status :-{" "}
                  {TrackData.Status ? Sts[TrackData.Status] : Sts[0]}
                </li>
              </ul>
            ) : (
              <ul className="w3-ul w3-border w3-sand w3-card w3-center">
                <li className="w3-padding-small">{Comments}</li>
              </ul>
            )}
            <p className="w3-center">
              <button
                onClick={CancelData}
                type="button"
                className="w3-button w3-red"
              >
                Cancel
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrackDetails;
