// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCUJR7EqS1EFaWOGbUIpZi7vBb6p5EAmDc",
  authDomain: "metrocourior.firebaseapp.com",
  projectId: "metrocourior",
  storageBucket: "metrocourior.appspot.com",
  messagingSenderId: "482652655926",
  appId: "1:482652655926:web:a6d3fc22e1d132ff746397",
  measurementId: "G-37JLRWYKLD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const Db = getFirestore(app);

export default Db;
