import React, { useState, useContext } from "react";
import "../W3.css";
import Store from "./../Components/Store";
import ConsignDate from "./../Components/ConsignDate";
import ConsignFrom from "./../Components/ConsignFrom";
import ConsignTable from "./../Components/ConsignTable";

const Consignment = () => {
  const [FormData, SetFormData] = useState("");
  const { SetUserData } = useContext(Store);
  const ClickLogout = () => {
    SetUserData(false);
  };

  return (
    <div className="w3-container w3-sans-serif">
      <div className="w3-panel w3-white">
        <div>
          <h4 className="w3-text-green " style={{ textShadow: "1px" }}>
            <b><i>METRO COURIER & CARGO</i></b>
          </h4>
          <span className="w3-text-orange w3-right w3-large">
            {" "}
            Hi, <b>METRO...</b>
          </span>
        </div>
      </div>
      <div className="w3-bar w3-green w3-round-xlarge">
        <button className="w3-bar-item w3-button w3-border w3-mobile">
          Consignment
        </button>
        <button
          onClick={ClickLogout}
          className="w3-bar-item w3-button w3-ripple w3-border w3-right w3-mobile"
        >
          Log out
        </button>
      </div>
      <div className="w3-container">
        <ConsignDate Gt={FormData} St={SetFormData} />
      </div>
      <ConsignFrom Gt={FormData} St={SetFormData} />
      <br />
      <ConsignTable Gt={FormData} St={SetFormData} />
    </div>
  );
};

export default Consignment;
