import Db from "./Db";
import { collection, addDoc } from "firebase/firestore";

const InsertFormData = async (FromData) => {
  try {
    await addDoc(collection(Db, "ConsinData"), FromData);
    //return docRef.id;
    return true;
  } catch (error) {
    return false;
  }
};

export { InsertFormData };
