import React, { useState, useEffect } from "react";
import { SelectBillDate } from "../Config/Select";

// const DateN = () => {
//   let today = new Date();
//   let dd = String(today.getDate()).padStart(2, "0");
//   let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
//   let yyyy = today.getFullYear();
//   today = yyyy + "-" + mm + "-" + dd;
//   return today;
// };

const ConsignDate = (props) => {
  const [inputs, setInputs] = useState({}); //CDate: DateN()
  const [Reload, SetReload] = useState(false);
  const [Comment, SetComment] = useState("");
  const [EditOn, SetEditOn] = useState("");

  useEffect(() => {
    if (Comment) {
      setTimeout(() => {
        SetComment("");
      }, 3000);
    }
  }, [Comment, SetComment]);

  useEffect(() => {
    //rceived from table send to form
    if (EditOn) {
      let PrvData = { ...props.Gt };
      PrvData.EditData = EditOn;
      props.St(PrvData);
      SetEditOn("");
    }
    return () => SetEditOn("");
  }, [EditOn, SetEditOn, props]);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
    SetReload(true);
  };

  useEffect(() => {
    if (Reload) {
      Promise.resolve(SelectBillDate(inputs.CDate)).then((val) => {
        if (val) {
          props.St({
            Billdate: inputs.CDate,
            BillData: val,
            BillLength: val.length || 0,
            Edit: EditOn,
            Setcomment: SetComment,
            Setreload: SetReload,
            SetEditOn: SetEditOn,
          });
        }
      });
      SetReload(false);
    }
    return () => SetReload(false);
  }, [Reload, inputs, SetReload, props, SetEditOn, EditOn]);

  return (
    <>
      <form className="w3-row w3-small">
        <div className="w3-col" style={{ width: "80%" }}>
          <input
            name="CDate"
            className="w3-input w3-hide-large w3-small"
            type="date"
            value={inputs.CDate || ""}
            onChange={handleChange}
          />
        </div>
        <div className="w3-col" style={{ width: "15%" }}>
          <input
            name="CDate"
            className="w3-input w3-hide-small"
            type="date"
            value={inputs.CDate || ""}
            onChange={handleChange}
          />
        </div>
        <div className="w3-rest">
          <h6 className="w3-center w3-text-red">{Comment}</h6>
        </div>
      </form>
    </>
  );
};

export default ConsignDate;
