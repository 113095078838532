import React, { useState, useEffect } from "react";
import { InsertFormData } from "../Config/Insert";
import { DeleteTableRow, UpdateTableRow } from "../Config/UpdateDelete";
import "../W3.css";

const ConsignFrom = (props) => {
  const [inputs, setInputs] = useState({});
  const [Modify, SetModify] = useState(false);
  const [ModifyData, SetModifyData] = useState(2);
  const { EditData } = props.Gt || "";

  useEffect(() => {
    //receive from date edit data
    if (EditData) {
      setInputs(EditData);
      let PresentData = { ...props.Gt };
      PresentData.EditData = "";
      props.St(PresentData);
      SetModify(true);
    }
  }, [EditData, props, SetModify]);

  useEffect(() => {
    //add edit delete
    switch (ModifyData) {
      case 1:
        let today = new Date(props.Gt.Billdate);
        let dd = String(today.getDate()).padStart(2, "0");
        let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = today.getFullYear();

        const InsertData = {
          CDDay: dd,
          CDMonth: mm,
          CDYear: yyyy,
          SL: props.Gt.BillLength + 1,
          CDate: props.Gt.Billdate,
          ...inputs,
        };
        Promise.resolve(InsertFormData(InsertData)).then((val) => {
          if (val) {
            props.Gt.Setcomment("Insert Sucessfully");
            props.Gt.Setreload(true);
            setInputs("");
          } else {
            props.Gt.Setcomment("Error : Inserting Problem");
            props.Gt.Setreload(true);
          }
        });
        break;
      case 0:
        Promise.resolve(UpdateTableRow(inputs.index, inputs)).then((val) => {
          if (val) {
            props.Gt.SetEditOn("");
            props.Gt.Setcomment("Sucessfully Update");
            props.Gt.Setreload(true);
          }
        });
        SetModify(false);
        setInputs("");
        break;
      case -1:
        Promise.resolve(DeleteTableRow(inputs.index)).then((val) => {
          if (val) {
            props.Gt.SetEditOn("");
            props.Gt.Setcomment("Sucessfully Deleted");
            props.Gt.Setreload(true);
          }
        });
        SetModify(false);
        setInputs("");
        break;
      default:
        break;
    }
    return () => SetModifyData(2);
  }, [ModifyData, inputs, props, SetModify, setInputs]);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleAdd = (event) => {
    event.preventDefault();
    if (props.Gt) {
      SetModifyData(1);
    } else {
      props.Gt.Setcomment("Date Input Not to be Empty");
    }
  };

  const HandelEdit = (event) => {
    event.preventDefault();
    SetModifyData(0);
  };

  const HandelDelete = (event) => {
    event.preventDefault();
    SetModifyData(-1);
  };

  return (
    <div className="w3-container">
      <form>
        <div className="w3-row w3-small">
          <div className="w3-quarter">
            <input
              className="w3-input"
              type="text"
              placeholder="Consign No"
              name="BillNo"
              value={inputs.BillNo || ""}
              onChange={handleChange}
            />
            <input
              className="w3-input"
              type="text"
              placeholder="Invoice No"
              name="RefNo"
              value={inputs.RefNo || ""}
              onChange={handleChange}
            />
          </div>
          <div className="w3-quarter">
            <input
              className="w3-input"
              type="text"
              placeholder="Location From"
              name="LFrom"
              value={inputs.LFrom || ""}
              onChange={handleChange}
            />
            <input
              className="w3-input"
              type="text"
              placeholder="Location To"
              name="LTo"
              value={inputs.LTo || ""}
              onChange={handleChange}
            />
          </div>
          <div className="w3-quarter">
            <input
              className="w3-input"
              type="text"
              placeholder="Delivery Date"
              name="DDate"
              value={inputs.DDate || ""}
              onChange={handleChange}
            />
            <div className="w3-row">
              <input
                className="w3-input w3-col"
                type="text"
                style={{ width: "40%" }}
                placeholder="No Packages"
                name="Packages"
                value={inputs.Packages || ""}
                onChange={handleChange}
              />
              <select
                className="w3-select w3-col w3-border-0"
                style={{ width: "40%" }}
                name="PackagesNm"
                value={inputs.PackagesNm || "Boxes"}
                onChange={handleChange}
              >
                <option value="0">Boxes</option>
                <option value="1">Bages</option>
                <option value="2">Packs</option>
              </select>
            </div>
          </div>
          <div className="w3-quarter">
            <div className="w3-row">
              <input
                className="w3-input w3-col"
                style={{ width: "40%" }}
                type="text"
                placeholder="Weight"
                name="Weights"
                value={inputs.Weights || ""}
                onChange={handleChange}
              />
              <select
                className="w3-select w3-col w3-border-0"
                style={{ width: "40%" }}
                name="WeightsNm"
                value={inputs.WeightsNm || "KG"}
                onChange={handleChange}
              >
                <option value="0">KG</option>
                <option value="1">Ton</option>
                <option value="2">Gram</option>
              </select>
            </div>
            <div>
              <select
                className="w3-select w3-col w3-border-0"
                style={{ width: "100%" }}
                name="Status"
                value={inputs.Status || "Godown In"}
                onChange={handleChange}
              >
                <option value="0">Godown In</option>
                <option value="1">Deliver In Good Condition</option>
                <option value="2">In Transit</option>
              </select>
            </div>
            {Modify ? (
              <div>
                <button
                  onClick={HandelEdit}
                  className="w3-btn w3-orange w3-left"
                  style={{ width: "40%" }}
                >
                  Edit
                </button>
                <button
                  onClick={HandelDelete}
                  className="w3-btn w3-red"
                  style={{ width: "40%" }}
                >
                  Delete
                </button>
              </div>
            ) : (
              <button
                onClick={handleAdd}
                className="w3-btn w3-orange"
                type="submit"
                style={{ width: "100%" }}
              >
                Add
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default ConsignFrom;
