import { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./Screens/Home";
import Consignment from "./Screens/Consignment";
import Error from "./Screens/Error";
import Store from "./Components/Store";
import ConsignData from "./Screens/ConsignData";

function App() {
  const [UserData, SetUserData] = useState(false);
  const [TransData, SetTransData] = useState({ Data: false, Docs: "" });
  const [PathData, SetPathData] = useState(
    "/" + Math.floor(Math.random() * 100000000) + 1
  );
  return (
    <Store.Provider
      value={{
        UserData,
        SetUserData,
        PathData,
        SetPathData,
        TransData,
        SetTransData,
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route>
            <Route index element={<Home />} />
            <Route
              path="/ConsignData"
              element={<ConsignData id={TransData} />}
            />
            <Route path="*" element={<Error />} />
            <Route
              path={PathData}
              element={UserData ? <Consignment id={UserData} /> : <Home />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </Store.Provider>
  );
}

export default App;
