import React, { useContext, useEffect, useState } from "react";
import "../W3.css";
import Store from "./../Components/Store";
import ConsignTbr from "./../Components/ConsignTbr";

const ConsignData = () => {
  const { TransData } = useContext(Store);
  const [DataOn, SetDataOn] = useState(TransData.Data);
  const [DataPut, SetDataPut] = useState("");
  const [Result, SetResult] = useState("");
  const [Dt, SetDt] = useState("");

  useEffect(() => {
    if (DataOn) {
      SetDataPut(TransData);
    }
    return () => SetDataOn(false);
  }, [DataOn, SetDataOn, TransData, SetDataPut]);

  useEffect(() => {
    if (DataPut) {
      SetResult(DataPut.Docs);
      SetDt(DataPut.Dates);
    }
    return () => SetDataPut("");
  }, [DataPut, SetResult, SetDt]);

  return (
    <div className="w3-container w3-small">
      <div className="w3-panel w3-white">
        <div>
          <h2 className="w3-text-green" style={{ textShadow: "1px" }}>
            <b>METRO COURIER & CARGO</b>
          </h2>
          <p>50, Janpath Rd,Ashok Nagar, Bhubaneswar</p>
          <p>GSTIN :- 21ADMPT8659B1ZB</p>
          <p>Email: metrocouriercargo@rediffmail.com</p>
        </div>
      </div>
      <div>
        <div className="w3-container">
          <p>
            From Date :- <span>{Dt.FDate}</span> To Date :-{" "}
            <span>{Dt.TDate}</span>
          </p>
          <div className="w3-responsive">
            <table className="w3-table-all w3-small">
              <thead>
                <tr>
                  <th>Sl No</th>
                  <th>Con-No</th>
                  <th>Receive Date</th>
                  <th>Delivery Date</th>
                  <th>Inv. No</th>
                  <th>Party Name</th>
                  <th>Destination</th>
                  <th>Box</th>
                  <th>Weight</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {Result
                  ? Result.map((value, index) => (
                      <ConsignTbr
                        key={index}
                        v={value.Doc}
                        k={index}
                        no={index}
                        Edit={""}
                        HomeP={true}
                      />
                    ))
                  : false}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsignData;
