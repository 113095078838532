import { useEffect, useState, useContext } from "react";
import { CheckDbuser } from "../Config/Select"; //SelectOwnerPass,
import { useNavigate } from "react-router-dom"; //useLocation
import "../W3.css";
import Store from "./Store";

const Login = (props) => {
  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");
  const [Go, SetGo] = useState("");
  const navigate = useNavigate();
  const { SetUserData } = useContext(Store);
  const { PathData } = useContext(Store);
  const [Comment, SetComment] = useState("");

  useEffect(() => {
    if (Go) {
      navigate(PathData, { replace: true });
    }
  }, [Go, PathData, navigate]);

  const handleLogin = () => {
    Promise.resolve(CheckDbuser(user, pass)).then((val) => {
      SetGo(val);
      SetUserData(val);
      if (!val) {
        SetComment("Please Correct Username Password");
      }
    });
  };

  useEffect(() => {
    if (props.modal.Show) {
      document.getElementById("id06").style.display = "block";
    }
  }, [props.modal.Show]);

  const CancelData = () => {
    document.getElementById("id06").style.display = "none";
    props.modal.SetShow(false);
  };

  return (
    <div className="w3-container">
      <div id="id06" className="w3-modal">
        <div
          className="w3-modal-content w3-card-4 w3-animate-zoom w3-round-xlarge"
          style={{ maxWidth: "600px" }}
        >
          <div className="w3-container w3-padding">
            <div className="w3-section">
              <label>
                <b>Username</b>
              </label>
              <input
                className="w3-input w3-border w3-margin-bottom"
                type="text"
                placeholder="Enter Username"
                onChange={(e) => setUser(e.target.value)}
              />
              <label>
                <b>Password</b>
              </label>
              <input
                className="w3-input w3-border"
                type="password"
                placeholder="Enter Password"
                onChange={(e) => setPass(e.target.value)}
              />
              <button
                onClick={handleLogin}
                className="w3-button w3-block w3-green w3-section w3-padding"
                type="submit"
              >
                Login
              </button>
            </div>
          </div>
          <div className="w3-container w3-center w3-border-top w3-padding-16 w3-light-grey">
            <p className="w3-orange w3-padding-small w3-round-xlarge w3-text-white">
              {Comment}
            </p>
            <button
              onClick={CancelData}
              type="button"
              className="w3-button w3-red"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
